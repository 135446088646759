var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { InstitutionService } from '../institution.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditService } from '../audit-form/audit.service';
import { Location } from '@angular/common';
import { ComplianceService } from '../compliance/compliance.service';
import { BaseLoginRequiredComponent } from '../base';
import { AccountService } from '../accounts.service';
import { AnalyticsService } from '../analytics.service';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { SubmitPreviewFormComponent } from './submit-preview-form/submit-preview-form.component';
import { catchError, mergeMap } from 'rxjs/operators';
var SubmitPreviewComponent = /** @class */ (function (_super) {
    __extends(SubmitPreviewComponent, _super);
    function SubmitPreviewComponent(accountService, router, auditService, route, institutionService, location, analytics, complianceService, translateService, logger) {
        var _this = _super.call(this, router, route, accountService, logger) || this;
        _this.auditService = auditService;
        _this.route = route;
        _this.institutionService = institutionService;
        _this.location = location;
        _this.analytics = analytics;
        _this.complianceService = complianceService;
        _this.translateService = translateService;
        _this.auditForm = null;
        _this.ward = null;
        _this.auditId = null;
        return _this;
    }
    SubmitPreviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.auditId = +params['audit_id'];
            if (isNaN(_this.auditId))
                throw new Error('Audit ID is NaN');
            _this.auditService.setAuditId(_this.auditId);
            _this.auditService.getAuditWithFormSchema(_this.auditId).subscribe(function (result) {
                _this.audit = result[0], _this.auditForm = result[1], _this.auditFormSchema = result[2];
                _this.institutionService.getWard(_this.audit.wardId).subscribe(function (ward) { return _this.ward = ward; });
                _this.complianceScore = _this.complianceService.calculateAuditCompliance(_this.auditFormSchema, _this.audit);
                _this.complianceImage$ = _this.complianceService.getComplianceImageUrl(_this.auditForm, _this.complianceScore);
            });
        });
    };
    SubmitPreviewComponent.prototype.onCancelClicked = function () {
        var _this = this;
        this.analytics.trackButtonClick('Cancel');
        this.analytics.trackAction('Audit submit preview cancelled');
        this.save().subscribe(function () { return _this.location.back(); });
    };
    SubmitPreviewComponent.prototype.onSubmitClicked = function () {
        var _this = this;
        var config = this.auditForm.config;
        if (config && config.require_signature && this.audit.auditSession.signatures.length === 0) {
            alert(this.translateService.instant('audit-submission.signature-required-error'));
            return;
        }
        this.analytics.trackButtonClick('Submit');
        this.analytics.trackAction('Audit preview submit');
        if (this.previewForm.submit()) {
            this.save().subscribe(function () { return _this.router.navigate(['audit-submit', _this.audit.id]); });
        }
    };
    SubmitPreviewComponent.prototype.save = function () {
        var _this = this;
        return this.auditService.getAudit(this.audit.id).pipe(mergeMap(function (audit) { return _this.auditService.saveAudit(audit); }), catchError(function (error) {
            _this.logger.error('Could not save audit', error);
            return of(false);
        }));
    };
    return SubmitPreviewComponent;
}(BaseLoginRequiredComponent));
export { SubmitPreviewComponent };
